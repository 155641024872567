import NextLink from "next/link"
import cn from "classnames"
import isInternalLink from "utilities/isInternalLink"

export default function CallToAction({
  href,
  target = null,
  children,
  style = "primary",
  button = false,
  className,
  ...delegated
}) {
  const ctaStyles = {
    primary:
      "text-center inline-block  primary-link px-6 py-1.5 text-m1 sm:text-lg font-bold bg-green-apple border-[3px] border-green-apple rounded-full no-underline uppercase hover:bg-green-pine hover:border-green-pine hover:text-white",
    secondary:
      "text-center inline-block secondary-link disabled:border-gray-300 disabled:text-gray-500 disabled:cursor-not-allowed px-6 py-1.5 text-m1 sm:text-lg font-bold bg-white border-[3px] border-green-apple rounded-full no-underline uppercase hover:border-green-sea",
    tertiary:
      "text-center inline-block tertiary-link px-6 py-1.5 sm:text-lg text-white font-bold bg-gray-dark border-[3px] border-gray-dark rounded-full no-underline hover:bg-green-pine hover:border-green-pine",
    quaternary:
      "text-center quaternary-link px-6 py-1.5 text-m1 sm:text-lg font-bold text-black border-[3px] border-white rounded-full no-underline uppercase hover:border-green-sea",
    inline: "inline-link underline font-bold hover:no-underline text-in",
  }
  const styles = cn(
    "cursor-pointer max-w-full transition-all duration-300 ease-out", // default styles
    ctaStyles[style],
    className
  )

  if (button) {
    return (
      <button className={styles} {...delegated}>
        {children}
      </button>
    )
  }

  if (!href)
    return (
      <span data-type="invalid-link" className={styles}>
        {children}
      </span>
    )

  const internal = isInternalLink(href)

  target = target ?? (internal ? "_self" : "_blank")

  if (href.startsWith("tel:") || href.startsWith("mailto:")) {
    target = "_self"
  }

  return internal ? (
    <NextLink href={href} data-type="route" className={styles} {...delegated}>
      {children}
    </NextLink>
  ) : (
    <a data-type="external" className={styles} target={target} href={href} {...delegated}>
      {children}
    </a>
  )
}
